import './_body-view-height';
import './header';
//import './_slideshow.js';

let skipHeader = document.querySelector('a.visually-hidden.skip-to-main');
if(skipHeader != undefined) {
    skipHeader.addEventListener('focusin', function (e) {
        this.classList.add('focused');  
    });
    skipHeader.addEventListener('focusout', function (e) {
        this.classList.remove('focused');  
    });
}
import './_archive-unit-filter.js';
import './_single-carousel-slider-configuration.js';
import './_referral-links-slider-configuration.js';
import './_gmap-draw.js';
import './_block-project-area-map.js';
import './_map-styles.js';
import './_polygon.js';
import './_block-project-units.js';
import './_favorite-houses.js';
import './_hero-slider-configuration.js';
import './_animate-text.js';
import './_block-project-units-slider-configuration.js';
import './_single-prosjekt-light-box.js';
import './_image-gallery.js';