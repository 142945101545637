import Glide from "@glidejs/glide";
let glide = null;

function initHeroSlider(id) {
    glide = new Glide('#' + id, {
        type: 'carousel',
        autoplay: 5000,
        animationDuration: 800,
        rewind: false, 
        bound: true,
        startAt: 0,
        gap: 0,
        perView: 1,
    });
    
    glide.mount();
}

export function moveGlide(whichWay) {
    glide.go(whichWay);
}

window.initHeroSlider = initHeroSlider;