let gallery = document.querySelectorAll('.image-gallery');

if(gallery.length > 0) {
    let sliderImg = document.querySelectorAll('.gallery-image');
    let imageLightBox = document.querySelectorAll('.gallery-image-light-box');
    let exitLightbox = document.querySelectorAll(".gallery-exit-background");
    let body = document.querySelector("body");
    let leftArrow = document.querySelectorAll(".gallery-left-arrow")
    let rightArrow = document.querySelectorAll(".gallery-right-arrow")


    for (let i = 0; i < sliderImg.length; i++) {
        sliderImg[i].addEventListener('click', function(event) {
            imageLightBox[i].classList.add("active");
            body.style.overflow = "hidden";
            setTimeout(() => {
                imageLightBox[i].classList.add("animate-show");
            }, 10);
        });

        exitLightbox[i].addEventListener('click', function(event) {
            imageLightBox[i].classList.remove("animate-show");
            setTimeout(() => {
                imageLightBox[i].classList.remove("active");
                body.style.overflow = "visible";
            }, 300);
        });
    }

    for(let i=0; i<leftArrow.length;i++) {
        leftArrow[i].addEventListener('click', function(event) {
            imageLightBox[i-1].classList.add("active");
            setTimeout(() => {                
                imageLightBox[i].classList.remove("animate-show");
                imageLightBox[i-1].classList.add("animate-show");
            }, 10);
            setTimeout(() => {
                imageLightBox[i].classList.remove("active");
            }, 300);
        });

        rightArrow[i].addEventListener('click', function(event) {
            imageLightBox[i+1].classList.add("active");
            setTimeout(() => {                
                imageLightBox[i].classList.remove("animate-show");
                imageLightBox[i+1].classList.add("animate-show");
            }, 10);
            setTimeout(() => {
                imageLightBox[i].classList.remove("active");
            }, 300);
        });
    }
}