import DrawPolygons from "./_polygon";

let polygon = new DrawPolygons();

$( document ).ready(function() {
    let buttonDrawPolygon = $('#button-polygon-start-drawing-1');
    let unitDetails = document.querySelectorAll('.unit-details');
    let unitDetail = document.querySelectorAll('.unit-detail');

    if (window.location.hash) {
        let id = window.location.hash.substring(1);
        if ($('#' + id).hasClass('unit-detail')) {
            showUnit(id);
        }
    }

    unitDetail.forEach(element => {
        element.style.display = "none";
    });

    unitDetails.forEach(element => {
        if (window.location.hash) {
            let id = window.location.hash.substring(1);
            if ($('#' + id).hasClass('unit-detail')) {    
                $('#' + id)[0].style.display = "block";
                $('#' + id)[0].style.opacity = "1";
                showUnit(id);
            } else {
                element.style.display = "none";
                element.style.opacity = "1";
            }
        } 
    });


    if(buttonDrawPolygon) {
        buttonDrawPolygon.attr('onclick','startPolygonDrawing(this, 0)');
    }
});

$('.project-units .unit-list .units > div a').click(function(e) {
    var unitId = $(e.target).parents('a').data('unitId');
    showUnit(unitId);
}).hover(function(e) {
    var unitId = $(e.target).parents('a').data('unitId');
    $('.project-units .preview polygon#' + unitId + '-0-poly').addClass('highlighted');
    $('.project-units .preview polygon#' + unitId + '-1-poly').addClass('highlighted');
}, function(e) {
    var unitId = $(e.target).parents('a').data('unitId');
    $('.project-units .preview polygon#' + unitId + '-0-poly').removeClass('highlighted');
    $('.project-units .preview polygon#' + unitId + '-1-poly').removeClass('highlighted');
});

$('.project-units .show-hide-sold-units').click(function(e) {
    e.preventDefault();

    var $this = $(this);
    $this.toggleClass('hide');
    var isHidden = $this.hasClass('hide');

    var $rows = $this.siblings('div').find('a.sold');
    if (isHidden) {
        $rows.removeClass('show');
    } else {
        $rows.addClass('show');
    }

    $this.text(isHidden ? 'Vis solgte boliger' : 'Skjul solgte boliger');
});


function showUnit(unitId) {
    var $unitDetails = $('.project-units .unit-details');
    var $unit = $unitDetails.find('#' + unitId);

    $unitDetails.show();
    $unitDetails.find('.unit-detail').hide();
    $unit.show();
    $unitDetails.addClass('open');

    $('html, body').animate({
        scrollTop: $unit.offset().top - 130
    }, 300);
}


if ($('.unit-list').length > 0) {
    $(window).scroll(function(e) {
        if (window.innerWidth < 1000) {
            return;
        }

        var headerHeight = 130 + ($('#wpadminbar').outerHeight() || 0) + 20; // 20px from the bottom of the header

        var $unitLists = $('.unit-list');
        var scrollTop = $(e.target).scrollTop();

        for (var i = 0; i < $unitLists.length; i++) {
            var $unitList = $($unitLists[i]);
            var offsetTop = $unitList.offset().top;
            var unitListHeight = $unitList.height();
            var $svg = $unitList.find('.preview > svg');

            var svgHeight = $svg.height();

            // Clamp the diff between 0 and (unitListHeight - svgHeight)
            // At this point the svg will be at the bottom of the unit list
            var diff = Math.min(Math.max(scrollTop - offsetTop + headerHeight, 0), unitListHeight - svgHeight);
            $unitList.find('svg').css('transform', 'translateY(' + diff + 'px)');

        }
    });
}

var $addFavoriteLinks = $('.add-favorite');
$addFavoriteLinks.find('> a').click(function(e) {
    e.preventDefault();

    var $this = $(this);
    var pageId = parseInt($this.data('pageId')) || 0;
    var unitId = $this.data('unitId');

    if (pageId && unitId) {
        var currentFavorites = getFavorites();
        addToFavorites(pageId, unitId);

        var $favoritesLinksInUnit = $this.parents('.unit-detail').find('.add-favorite');

        var favoritesNum = getFavorites().length;
        if (favoritesNum > currentFavorites.length) {
            $favoritesLinksInUnit.addClass('is-favorite');
            $favoritesLinksInUnit.find('span').text('Merket som favoritt');
        } else {
            $favoritesLinksInUnit.removeClass('is-favorite');
            $favoritesLinksInUnit.find('span').text('Merk som favoritt');
        }

        var $favoritesLink = $('.favorites-link');
        if (favoritesNum > 0) {
            $favoritesLink.addClass('has-favorites');
            $favoritesLink.find('text').text('' + favoritesNum);
        } else {
            $favoritesLink.removeClass('has-favorites');
            $favoritesLink.find('text').text('');
        }
    }
});

// var favorites = getFavoritesFlat();
// $addFavoriteLinks.each(function(index, el) {
//     var $el = $(el).find('> a');

//     if (favorites.indexOf('' + $el.data('pageId') + $el.data('unitId')) !== -1) {
//         $el.parent().addClass('is-favorite');
//     }
// });

var $polygons = $('.project-units .preview polygon');
$polygons.hover(function(e) {
    var $this = $(this);
    var $wrapper = $this.parents('.project-units');
    $wrapper.find('.units a[data-unit-id="' + $this.data('id') + '"]').addClass('active');

    var id = $this.data('id');
    var index = $this.data('index') === 0 ? 1 : 0;
    $('#' + id + '-' + index + '-poly').addClass('highlighted');
}, function(e) {
    var $this = $(this);
    var $wrapper = $this.parents('.project-units');
    $wrapper.find('.units a[data-unit-id="' + $this.data('id') + '"]').removeClass('active');

    var id = $this.data('id');
    var index = $this.data('index') === 0 ? 1 : 0;
    $('#' + id + '-' + index + '-poly').removeClass('highlighted');
});

$polygons.click(function(e) {
    if (!$(this).hasClass('available')) {
        return;
    }

    var unitId = $(this).data('id');
    showUnit(unitId);

    if (history.pushState) {
        history.pushState(null, null, '#' + unitId);
    }
});

function slugify(title) {
    var str = title;
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

polygon.setPolygonCallback(function (data) {
    if (!data.polyName) {
        return;
    }

    // Update input field with new points
    var $inputs = $('[data-key="field_648035973d25b"] input');
    var polygonFieldInputId = data.polyIndex === 0 ? '648036623d266' : '648036713d267'

    for (var i = 0; i < $inputs.length; i++) {
        var $input = $($inputs[i]);

        if ($input.val() === data.polyName || $input.attr('value') === data.polyName) {
            $input.parents('.acf-row')
                .find('.acf-field-' + polygonFieldInputId + ' input')
                .val(data.points)
                .attr('value', data.points);
        }
    }

    $( ".acf-block-fields" ).each(function(  ) {
        var evt = document.createEvent("HTMLEvents");
        evt.initEvent("change", false, true);
        $(this)[0].dispatchEvent(evt);
    });
});

function setupFieldEditing(svgId) {
    polygon.setAllowEditing(true);
    polygon.editPolygon(svgId, 'random');
    polygon.setCircleRadius(8);
}

window.setupFieldEditing = setupFieldEditing;

function startPolygonDrawing(el, index) {
    var $svg = $('.block-editor-block-list__block.is-selected .preview > svg');
    var name = $(el).parents('.acf-row').find('[data-name="title"] input').val();
    var slug = slugify(name);

    polygon.drawPolygon($svg.attr('id'), slug + '-' + index, name, index);
}

window.startPolygonDrawing = startPolygonDrawing;

/*if (window.location.hash) {
    let id = window.location.hash.substring(1);
    if ($('#' + id).hasClass('unit-detail')) {
        showUnit(id);
    }
}*/
