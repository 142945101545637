import Glide from "@glidejs/glide";
let glide = null;

function initProjectUnitImages(id) {
    glide = new Glide('#' + id, {
        type: 'slider',
        rewind: false, 
        bound: true,
        startAt: 0,
        gap: 30,
        perView: 1,
        breakpoints: {
            1000: {
                perView: 1,
                gap: 20,
            },
        }
    });
    
    glide.mount();
}

export function moveGlide(whichWay) {
    glide.go(whichWay);
}

window.initProjectUnitImages = initProjectUnitImages;