
import { intersectionObserve } from "./_intersection-observer";

const fadeInText = document.querySelectorAll('.fade-in');

intersectionObserve(fadeInText, -100, 0, (element, intersectionRatio) => {
    (function(el) {
        if (intersectionRatio > 0 && !el.classList.contains('animate-in')) {
            el.classList.add('animate-in');
        } 
    })(element);
});

const slideInText = document.querySelectorAll('.slide-in');

intersectionObserve(slideInText, -200, 0, (element, intersectionRatio) => {
    (function(el) {
        if (intersectionRatio > 0 && !el.classList.contains('animate-in')) {
            el.classList.add('animate-in');
        } 
    })(element);
});

const slideUpText = document.querySelectorAll('.slide-up');

intersectionObserve(slideUpText, -50, 0, (element, intersectionRatio) => {
    (function(el) {
        if (intersectionRatio > 0 && !el.classList.contains('animate-in')) {
            el.classList.add('animate-in');
        } 
    })(element);
});