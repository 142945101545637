// hamburger nav

const breakpoint = 1200;

if (window.innerWidth < breakpoint) {
	window.addEventListener("load", (event) => {
		//const height =$("header").outerHeight();
		//$('#content-section').css('margin-top', height + 'px');
		const body = document.querySelector("body");
		const button = document.querySelector(".menu-button");
		const navBar = document.querySelector("header .menu");
		const header = document.querySelector("header");
		const parent = document.querySelectorAll(".menu-item-has-children >a");
		const arrow = document.querySelectorAll(".menu-item-has-children >svg");

		button.onclick = function toggleMenu() {
			if (navBar.matches(".show")) {
				body.style.overflow = "visible";
				navBar.classList.remove("anim");
				setTimeout(function () {
					navBar.classList.remove("show");
				}, 400);
				button.classList.remove("menu-close");
				header.classList.remove("show");
			} else {
				body.style.overflow = "hidden";
				navBar.classList.add("show");
				setTimeout(function () {
					navBar.classList.add("anim");
				}, 100);

				button.classList.add("menu-close");
				header.classList.add("show");

				// Hides dropdown when menu is hidden
				parent.forEach(function (ele) {
					ele.classList.remove("show-ul");
				});
			}
		};

		//dropdown

		/**
		 * check if parent has child with classname
		 * on click add or remove class
		 *
		 *
		 */

		parent.forEach(function (ele) {
			ele.addEventListener("click", function (e) {
				e.preventDefault();
				if (ele.parentNode.classList.contains("show-ul")) {
					window.location = e.target.href;
				} else {
					ele.parentNode.classList.add("show-ul");
				}
			});
		});
		arrow.forEach(function (ele) {
			ele.addEventListener("click", function (e) {
				e.preventDefault();
				if (ele.parentNode.classList.contains("show-ul")) {
					ele.parentNode.classList.remove("show-ul");
				} else {
					ele.parentNode.classList.add("show-ul");
				}
			});
		});
	});
} else {
	const links = document.querySelectorAll(".menu-item-has-children >a");
	// links.forEach(function (ele) {
	//     ele.addEventListener('focusin', function(e) {
	//         ele.parentNode.classList.add("show-ul");
	//     });
	// });

	//const height =$("header").outerHeight();
	//$('#content-section').css('margin-top', height + 'px');

	const descendants = document.querySelectorAll(
		" .menu-item-has-children > ul > li > a"
	);

	descendants.forEach((descendant) => {
		descendant.addEventListener("focus", (event) => {
			const grandParent =
				descendant.parentElement.parentElement.parentElement;
			console.log(grandParent);
			grandParent.classList.add("show-ul");
		});

		descendant.addEventListener("blur", (event) => {
			const grandParent =
				descendant.parentElement.parentElement.parentElement;
			grandParent.classList.remove("show-ul");
		});
	});

	links.forEach((link) => {
		link.addEventListener("focus", (event) => {
			link.parentNode.classList.add("show-ul");
		});

		link.addEventListener("blur", (event) => {
			link.parentNode.classList.remove("show-ul");
		});
	});
}
