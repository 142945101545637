import PolygonMap from './_gmap-draw'

var map = new PolygonMap();

var initMap = function() {

    $('#sub-map:not(.initialized)').each(function () {
        var $this = $(this);
        var isEditable = $this.hasClass('editmode');
        var centerLat = $this.data('lat');
        var centerLng = $this.data('lng');
        var centerZoom = $this.data('zoom');
        var mapType = $this.data('mapType');
        var zoomMobile = $this.data('zoom-mobile');
        var zoomDesktop = $this.data('zoom-desktop');

        if (window.google === undefined || window.google.maps === undefined) {
            return;
        }

        // var
        var geoJSONData = $this.siblings('.geojson').val();
        var geoJSON = {};
        try {
            geoJSON = JSON.parse(geoJSONData);
        } catch (e) {}

        var data = {};

        let defaultZoom;
        if(window.innerWidth <= 600) {
            defaultZoom = 15;
        }
        else {
            defaultZoom = 10;
            if(geoJSON && !centerZoom) {
                centerZoom = geoJSON.zoom;
            }
        }

        if (geoJSON && geoJSON['features'] && geoJSON['features'].length > 0 && geoJSON['features'][0]['geometry']['coordinates']) {
            var coords = [];
            var geoJSONCoords = geoJSON['features'][0]['geometry']['coordinates'];
            for (var i = 0; i < geoJSONCoords[0].length; i++) {
                var coord = geoJSONCoords[0][i];
                coords.push({lat: coord[1], lng: coord[0]});
            }
            data = {
                zoom: centerZoom || defaultZoom,
                center: {lat: 58.839317, lng: 5.683555},
                coords: coords
            };
        } else {
            data = geoJSON || {};
        }

        // Is this default data? See if we have map center data
        if (data && !data.zoom) {
            data.zoom = centerZoom || defaultZoom;
        }

        if (data && !data.center) {
            data.center = {
                lat: centerLat,
                lng: centerLng
            }
        }

        if(window.innerWidth <= 600 && zoomMobile) {
            centerZoom = zoomMobile;
        }
        else if(zoomDesktop) {
            centerZoom = zoomDesktop;
        }

        data.zoom = centerZoom || defaultZoom;

        var clickHandler = function(m) {
            var url = 'https://www.google.com/maps/dir/?api=1&destination=' + m.latLng.lat() + ',' + m.latLng.lng();
            window.open(url, '_blank');
        };

        var isEditMode = $(this).hasClass('editmode');
        map.setupMap('sub-map', {mapTypeId: mapType});
        map.mapAddPolygon(data, isEditable, polygonCallback, isEditMode ? null : clickHandler);

        $this.addClass('initialized');

        var bounds = new google.maps.LatLngBounds();
        var polygonCoords = data.coords;

        if (polygonCoords) {
            for (var i = 0; i < polygonCoords.length; i++) {
                bounds.extend(polygonCoords[i]);
            }
        
            map.map.setCenter(bounds.getCenter());
        } else {
            map.map.setCenter(data.center);
        }
    });
};

function polygonCallback(data) {

    var $geoJSON = $('.acf-field-647eefc46e97a input');
    if ($geoJSON.length > 0 && data.center.lat > 0 && data.coords && data.coords.length > 0) {
        var dataJSON = JSON.stringify(data);
        $geoJSON.val(dataJSON).attr('value', dataJSON);

        $(".acf-block-fields").each(function () {
            var evt = document.createEvent("HTMLEvents");
            evt.initEvent("change", false, true);
            $(this)[0].dispatchEvent(evt);
        });
    }
}

function addSubfieldMapPolygon(el) {
    map.mapAddPolygon(null, true, polygonCallback);
}

$('.subfield-map-action').on('click', function(el) {
    map.mapAddPolygon(null, true, polygonCallback);
})


window.initMap = initMap;
window.addSubfieldMapPolygon = addSubfieldMapPolygon;

