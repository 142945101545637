let unitFilter = document.querySelector(".unit-filter");
let activeTypeFilter = "All";
let activeLocationFilter = "All";

if (unitFilter) {
	let typeFilter = unitFilter.querySelectorAll(".unit-type");
	let locationFilter = unitFilter.querySelectorAll(".unit-location");
	let units = document.querySelectorAll(".tease");

	typeFilter.forEach((type) => {
		type.addEventListener("click", function (event) {
			activeTypeFilter = type.dataset.type;
			removeClass("active", typeFilter);
			type.classList.add("active");
			applyFilter(units);
		});
	});

	locationFilter.forEach((location) => {
		location.addEventListener("click", function (event) {
			activeLocationFilter = location.dataset.location;
			removeClass("active", locationFilter);
			location.classList.add("active");
			applyFilter(units);
		});
	});
}

function applyFilter(units) {
	const activeUnits = [...units]
		.filter((unit) => {
			if (activeTypeFilter === "All") {
				return true;
			} else {
				return unit.dataset.type === activeTypeFilter;
			}
		})
		.filter((unit) => {
			if (activeLocationFilter === "All") {
				return true;
			} else {
				return unit.dataset.location === activeLocationFilter;
			}
		});

	units.forEach((unit) => {
        if(activeUnits.includes(unit)) {
            unit.classList.remove("hidden");
        } else {
            unit.classList.add("hidden");
        }

	});
}

function removeClass(removeableClass, list) {
	for (let i = 0; i < list.length; i++) {
		list[i].classList.remove(removeableClass);
	}
}
