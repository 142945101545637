import Glide from "@glidejs/glide";
let glide = null;

function initSingleCarouselSlider(id) {
    glide = new Glide('#' + id, {
        type: 'carousel',
        autoplay: 5000, 
        bound: true,
        startAt: 0,
        gap: 0,
        perView: 1,
        breakpoints: {
            900: {
                perView: 1,
                gap: 10,
            }
        }
    });
    
    glide.mount();
}

export function moveGlide(whichWay) {
    glide.go(whichWay);
}

window.initSingleCarouselSlider = initSingleCarouselSlider;